import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Social from './social'
import data from '../assets/data.json'
import * as vars from '../export'

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: ${vars.colors.teal};
  width: 300px;
  will-change: max-height;
  transition: max-height .3s ease-out;

  @media(max-width: 900px) {
    width: 250px;
  }
  @media(max-width: ${vars.sizes.mTablet}) {
    width: 100%;
    max-width: none;
    max-height: ${props => (props.navOpen ? '800px' : '50px')};
    padding: ${props => (props.navOpen ? '0 0 20px 0' : '0')};
    overflow: hidden;

    .innerwrapper {
      width: 100%;
      padding: 0 20px;
    }
  }
`
const StyledUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0; padding: 0;
    margin-left: 10px;

    p {
      padding: 10px 0;
      color: ${vars.colors.white};
    }
    &:nth-child(1) {
      margin-left: 0;
      font-weight: 600;
    }
    &:nth-child(2) {
      font-size: 18px;
      font-weight: 600;
      color: ${props => (props.isOverall ? vars.colors.green : vars.colors.white)};
      margin-left: 0;
    }
    &:nth-child(12) a:after {
      content: '(USA data only)';
      font-size: 14px;
      font-weight: 300;
      width: 200px;
      margin-left: 5px;
    }
    &:last-of-type {
      margin-left: 0;
      margin-top: 10px;
      font-weight: 600;
    }
  }

  .active {
    color: ${vars.colors.green};
    font-weight: 600;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${vars.colors.white};
  font-size: 16px;
  will-change: color;
  transition: color .3s ease-out;

  &:hover {
    @media(hover: hover) {
      color: ${vars.colors.green};
    }
  }
`
const StyledP = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: ${props => (props.open ? vars.colors.green : vars.colors.white)};
  cursor: pointer;
  will-change: color;
  transition: color .3s ease-out;
  margin: 10px 0;

  &:hover {
    @media(hover: hover) {
      color: ${vars.colors.green};
    }
  }
`
const Burger = styled.div`
  position: relative;
  width: 50px; height: 50px;
  display: none;
  will-change: transform;
  transition: transform .3s ease-out;

  @media(max-width: ${vars.sizes.mTablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
    left: calc(100% - 50px);
  }

  &:hover {
    @media(hover: hover) {
      transform: scale(1.1);
    }
  }
  .close {
    width: 30px;
    height: 36px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    span {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: ${vars.colors.white};
      transition: all .3s ease-out;

      &:nth-child(1) {
        top: 10px;
        opacity: ${props => (props.navOpen ? 0 : 1)};
      }
      &:nth-child(2) {
        top: 50%;
        position: absolute;
        transform: translateY(-50%) rotate(${props => (props.navOpen ? '-45deg' : '0')});
        width: ${props => (props.navOpen ? '70%' : '100%')};
      }
      &:nth-child(3) {
        top: 50%;
        position: absolute;
        transform: translateY(-50%) rotate(${props => (props.navOpen ? '45deg' : '0')});
        width: ${props => (props.navOpen ? '70%' : '100%')};
      }
      &:nth-child(4) {
        bottom: 10px;
        opacity: ${props => (props.navOpen ? 0 : 1)};
      }
    }
  }
`

const Navigation = ({ location }) => {
  const [navOpen, setNavOpen] = useState(false)
  const [intro, setIntro] = useState(false)
  const [methodology, setMethodology] = useState(false)
  const currentPage = location.pathname.split('/')[1]
  const menuItemsArray = Object.keys(data[0]).filter(x => !x.includes('Links') && x !== 'Territory')
  useEffect(() => {
    setTimeout(() => setIntro(true), 1700)
  }, [])

  const closeMainPopups = () => {
    setNavOpen(false)
    setIntro(false)
    setMethodology(false)
  }
  const toggleIntro = () => {
    setNavOpen(!navOpen)
    setIntro(!intro)
  }
  const toggleMethodology = () => {
    setNavOpen(!navOpen)
    setMethodology(!methodology)
  }

  return (
    <NavigationWrapper navOpen={navOpen}>
      <div className='innerwrapper'>
        <Burger navOpen={navOpen} onClick={() => setNavOpen(!navOpen)}>
          <span className='close'><span/><span/><span/><span/></span>
        </Burger>
        <StyledP open={intro} onClick={toggleIntro}>Introduction</StyledP>
        <StyledUl dataLength={menuItemsArray.length} isOverall={menuItemsArray.includes('overall')}>
          <li>
            <StyledLink to='/overall/' className={(currentPage === 'overall' || currentPage === '') && !methodology && !intro ? 'active' : ''} onClick={() => setNavOpen(!navOpen)}>Most recurring backlinks</StyledLink>
          </li>
          <li>
            <p className={currentPage !== 'overall' && currentPage !== '' && !methodology && !intro ? 'active' : ''}>Industry</p>
          </li>
          {menuItemsArray.map(item => (
            <li key={item}>
              <StyledLink to={`/${item.toLowerCase().replace(/ /g, '').replace(/&/g, '')}/`} onClick={() => setNavOpen(!navOpen)} className={currentPage === item.toLowerCase().replace(/ /g, '').replace(/&/g, '') && !methodology && !intro ? 'active' : ''}>{item}</StyledLink>
            </li>
          ))}
          <li>
            <StyledLink to='/conclusions/' onClick={() => setNavOpen(!navOpen)} className={currentPage === 'conclusions' && !methodology && !intro ? 'active' : ''}>Key Takeaways</StyledLink>
          </li>
        </StyledUl>
        <StyledP open={methodology} onClick={toggleMethodology}>Methodology</StyledP>
      </div>
      <Social intro={intro} methodology={methodology} closeMainPopups={closeMainPopups}/>
    </NavigationWrapper>
  )
}
export default Navigation
