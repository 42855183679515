import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import * as d3 from 'd3'
import * as vars from '../export'

const BubbleVisWrapper = styled.div`
  width: 100%;
  position: relative;

  @media(max-width: ${vars.sizes.mDesktop}) {
    padding-bottom: 50px;
  }
`
const CirclePack = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;

  .circleText {
    font-size: 14px;
    fill: ${vars.colors.black}70;
    stroke-width: 0;
    font-weight: 600;
    user-select: none;
    pointer-events: none;
  }
  .svg-content {
    display: inline-block;
  }

  circle {
    will-change: stroke-width;
    transition: stroke-width .3s ease-out;

    &:hover{
      @media(hover: hover) {
        stroke-width: 2px !important;
        opacity: 1 !important;
      }
    }
  }

  .tooltip {
    background-color: ${vars.colors.white};
    border-radius: 0 10px 10px;
    box-shadow: 0 0 5px -2px rgba(0,0,0,0.5);
    padding: 10px;

    p {
      color: ${vars.colors.black};
      width: 100%;
      text-align: center;
      font-weight: 300;

      &:nth-child(2) {
        color: ${vars.colors.green};
        font-weight: 600;
      }
    }
  }
`
const AnalysisWrapper = styled.div`
  position: absolute;
  width: calc(100% - 20px);
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media(max-width: ${vars.sizes.mDesktop}) {
    width: 100%;
  }
`
const AnalysisButton = styled.div`
  display: inline-block;
  margin: 10px 0 20px;
  padding: 4px 10px;
  background-color: ${vars.colors.green};
  border-radius: 50px;
  cursor: pointer;
  will-change: transform;
  transition: transform .3s ease-out;

  p {
    color: ${vars.colors.white};
    margin: 0; padding: 0;
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;
  }
  &:hover {
    @media(hover: hover) {
      transform: scale(1.1);
    }
  }
`
const Analysis = styled.div`
  position: relative;
  background-color: ${vars.colors.blue};
  width: 100%;
  max-height: ${props => (props.open ? '250px' : '0px')};
  overflow: auto;
  left: 0; bottom: 0;
  will-change: max-height;
  transition: max-height .3s ease-out;

  .inner {
    padding: 20px;
  }
  h5, p {
    color: ${vars.colors.white};
    margin-bottom: 10px;
  }
  p:first-of-type {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 20px
  }
  h5 {
    margin: 30px 0 20px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

const BubbleVis = ({ data, setActiveHover }) => {
  const [AnalysisOpen, setAnalysisOpen] = useState(false)
  const [BubbleVisWrapperSize] = useState({ width: 1000, height: 600 })

  useEffect(() => {
    const { width, height } = BubbleVisWrapperSize

    const svg = d3.select('#CirclePack')
      .append('svg')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMaxYMax meet')
      .classed('svg-content', true)

    const maxLinks = Math.max(...data.map(o => o.Links))

    const colorPick = perc => (
      perc < 100 && perc > 20 ? `${vars.colors.green}${perc}`
        : perc < 100 ? `${vars.colors.green}20`
          : vars.colors.green
    )

    const dataLocal = data.map((x, index) => ({
      rank: index + 1,
      name: x.Site,
      links: x.Links,
      x: Math.floor(Math.random() * width) + 0,
      y: Math.floor(Math.random() * height) + 0,
      color: colorPick(((x.Links / maxLinks) * 100).toFixed(0))
    }))

    const size = d3.scaleLinear()
      .domain([0, maxLinks])
      .range([5, 70])

    const Tooltip = d3.select('#CirclePack')
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('position', 'absolute')

    const mousemove = d => {
      const posx = d3.event.pageX - document.getElementById('CirclePack').getBoundingClientRect().x + 10
      const posy = d3.event.pageY - document.getElementById('CirclePack').getBoundingClientRect().y + 10

      setActiveHover(d.name)

      Tooltip
        .html(`<p>${d.name}</p><p>${d.links}</p>`)
        .style('left', `${posx}px`)
        .style('top', `${posy}px`)
        .style('opacity', 1)
    }
    const mouseleave = () => {
      setActiveHover(undefined)

      Tooltip
        .style('opacity', 0)
    }
    const simulation = d3.forceSimulation()
      .force('center', d3.forceCenter().x(width / 2).y(height / 2))
      .force('collide', d3.forceCollide().strength(0.5).radius(d => size(d.links) + 2).iterations(5))
      .force('x', d3.forceX().strength(0.02).x(width / 2.5))
      .force('y', d3.forceY().strength(0.02).y(height / 2.5))

    const node = svg.append('g')
      .selectAll('g')
      .data(dataLocal)
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', d => `translate(${d.x}, ${d.y})`)
    node.append('circle')
      .attr('r', d => size(d.links))
      .style('fill', d => d.color)
      .style('fill-opacity', 0.8)
      .attr('stroke', vars.colors.green)
      .style('stroke-width', 0)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave)
    node.append('text')
      .classed('circleText', true)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'central')
      .text(d => (d.rank <= 10 ? d.name : ''))

    simulation
      .nodes(dataLocal).on('tick', () => {
        node.attr('transform', d => `translate(${d.x}, ${d.y})`)
      })
  }, []) // eslint-disable-line

  return (
    <BubbleVisWrapper id='BubbleVisWrapper'>
      <CirclePack id='CirclePack'/>
      <AnalysisWrapper>
        <Analysis open={AnalysisOpen}>
          <div className='inner'>
            <h5>Forbes</h5>
            <p>Forbes’ large number of outbound links may be due in part to contributor content. They do have a slightly more rigorous process than sites like BuzzFeed for recruiting contributors, and training sessions are provided, so for the purposes of this study, it has been retained.</p>
            <p>Moreover, Forbes is well-known and a prominent media presence with wide-spread interests, and links included are still very valuable.</p>
            <p>Forbes links are most prominent in the Career & Education and Health sectors where it is the number one publication for linking.</p>
            <h5>Evening Standard</h5>
            <p>Number one option for Auto & Vehicles and Business & Industry as well as Shopping, it’s also the UK number 1 for Career & Education, Finance, Food & Drink, Health, and Travel, and number 2 in the UK Arts & Entertainment, Finance, Shopping, and Travel areas, making it a reliable site for all niches.</p>
            <p>It’s also worth noting that it’s a UK-based publication – online sites of UK newspapers were remarkably prominent in the results, especially compared with the US equivalent, which only appeared in the form of the New York Times.</p>
            <p>And unlike either Forbes, there are no user-contributed materials involved, meaning all outbound links are from articles written by staff.</p>
            <h5>BuzzFeed</h5>
            <p>BuzzFeed – despite its focus on Arts & Entertainment (where it’s the overall top-linking result), is actually a remarkably wide-ranging publication, with outbound links in the majority of sectors – including Food & Drink, where it also holds the top position.</p>
          </div>
        </Analysis>
        <AnalysisButton onClick={() => setAnalysisOpen(!AnalysisOpen)}><p>Analysis</p></AnalysisButton>
      </AnalysisWrapper>
    </BubbleVisWrapper>
  )
}

export default BubbleVis
