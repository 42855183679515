import React, { useState } from 'react'
import { Switch } from 'react-router'
import { Route, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import WebFont from 'webfontloader'
import Navigation from './components/navigation'
import Main from './pages/main'
import './index.css'
import * as vars from './export'

WebFont.load({ typekit: { id: 'fxn6cah' } })

const Wrapper = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;

  @media(max-width: ${vars.sizes.mTablet}) {
    flex-direction: column;
  }
`
function App({ location }) {
  const [territory, setTerritory] = useState(localStorage.getItem('territory') ? localStorage.getItem('territory') : 'UK')

  return (
    <Wrapper>
      <Navigation location={location} territory={territory}/>
      <Switch location={location}>
        <Route exact path='/' render={props => <Main {...props} territory={territory} setTerritory={setTerritory}/>}/>
        <Route exact path='/:type' render={props => <Main {...props} territory={territory} setTerritory={setTerritory}/>}/>
      </Switch>
    </Wrapper>
  )
}

export default withRouter(App)
