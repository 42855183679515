import React from 'react'
import styled from 'styled-components'
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'
import * as vars from '../export'

const SocialWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 50px;
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  max-width: 150px;
  padding: 0 10px;

  @media(max-width: ${vars.sizes.mTablet}) {
    max-width: 120px;
  }
`
const StyledShareContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 20px; height: 20px;
  margin-right: 10px;
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  fill: none;
  stroke: ${vars.colors.white};
  stroke-miterlimit: 10;
  stroke-width: 0.75px;
  will-change: transform;
  transition: transform .3s ease-out;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.2);
    }
  }
  @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
    width: 20px; height: 20px;
    background-size: contain !important;
  }

  > div, a, button {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 0;
    color: transparent;
  }
  &:last-of-type {
    margin-right: 0;
  }

  &.facebook {
    background-image: url(${vars.icons.facebook});
  }
  &.twitter {
    background-image: url(${vars.icons.twitter});
  }
  &.linkedin {
    background-image: url(${vars.icons.linkedin});
  }
`
const Logo = styled.a`
  display: flex;
  margin: 20px auto;
  max-width: 150px;
  height: 40px;
  cursor: pointer;
  will-change: transform;
  transition: transform .3s ease-out;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.05);
    }
  }
  @media(max-width: ${vars.sizes.mTablet}) {
    max-width: 120px;
    height: 35px;
  }

  img {
    margin: 0;
    width: 100%; height: 100%;
    object-fit: contain;
    font-family: 'object-fit: contain;';
  }
`
const UnderLay = styled.div`
  position: absolute;
  width: 100%; height: 100%;
  left: 0; top: 0;
  background-color: ${vars.colors.black}50;
  z-index: 1;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  pointer-events: ${props => (props.isVisible ? 'auto' : 'none')};
  cursor: pointer;
  will-change: opacity;
  transition: opacity .3s ease-out;
`
const PopupBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  width: 100%;
  max-height: 80%;
  max-width: 700px;
  background-color: ${vars.colors.white};
  z-index: 2;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: ${props => (props.open ? 1 : 0)};
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
  will-change: opacity;
  transition: opacity .3s ease-out;

  @media(max-width: ${vars.sizes.mTablet}) {
    position: fixed;
    width: 90%;
  }

  > div {
    position: absolute;
    top: 15px; right: 20px;
    cursor: pointer;
    width: 20px; height: 20px;
    border-radius: 50%;
    background-color: ${vars.colors.green};
    will-change: transform;
    transition: transform .3s ease-out;

    @media (hover: hover) {
      &:hover {
        transform: scale(1.2);
      }
    }

    span {
      width: 100%; height: 100%;
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);

      &:before {
        content: '';
        position: absolute;
        top: 9px; left: 3px;
        width: 14px; height: 2px;
        background-color: ${vars.colors.white};
        transform: rotate(45deg);
      }
      &:after {
        content: '';
        position: absolute;
        top: 9px; left: 3px;
        width: 14px; height: 2px;
        background-color: ${vars.colors.white};
        transform: rotate(-45deg);
      }
    }
  }
  h5 {
    font-size: 18px;
    margin: 0 0 10px;
    padding: 0;
  }
  p {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Social = ({ intro, methodology, closeMainPopups }) => {
  const config = {
    clientUrl: 'https://klipr.io/',
    siteUrl: 'https://klipr.io/resources/backlinks-study/',
    twitter: '@KliprHQ',
    siteDescription: 'We analysed 1,166,411 backlinks in 11 sectors to find the publications that provide the most outbound links across industries. Explore and download the data.'
  }

  return (
    <SocialWrapper>
      <ButtonsWrapper>
        <StyledShareContainer className='linkedin'>
          <LinkedinShareButton url={config.siteUrl} title={config.siteDescription}/>
          <a href={config.linkedinUrl} target='_blank' rel='noreferrer noopener'>LinkedIn</a>
        </StyledShareContainer>
        <StyledShareContainer className='facebook'>
          <FacebookShareButton url={config.siteUrl} quote={config.siteDescription}/>
        </StyledShareContainer>
        <StyledShareContainer className='twitter'>
          <TwitterShareButton url={config.siteUrl} title={config.siteDescription} via={config.twitter}/>
        </StyledShareContainer>
      </ButtonsWrapper>
      <Logo href={config.clientUrl}><img src={vars.icons.logo} alt='Klipr.io'/></Logo>
      <PopupBlock open={intro}>
        <div onClick={closeMainPopups}><span/></div>
        <h5>Foreword:</h5>
        <p>This study aims to find the best publications in the UK and US for outreach by identifying which produce the most outbound links across different sectors, or overall. We analysed more than 1m backlinks in 11 popular sectors.</p>
        <p>From these findings, public relations and outreach professionals can see which websites are the most likely to create articles in each of these sectors.</p>
        <p>While links aren’t categorised by follow or nofollow, all sectors have been carefully researched by industry experts, and all sources relying on affiliate, forum-based, or sponsored links have been excluded where possible.</p>
        <p>While the data presented here has been significantly cleaned for analysis, you’re able to download the raw data on each individual page for your own study. Please find our full methodology inside.</p>
      </PopupBlock>
      <PopupBlock open={methodology}>
        <div onClick={closeMainPopups}><span/></div>
        <h5>Methodology</h5>
        <p><a href='https://www.similarweb.com/' target='_blank' rel='noopener noreferrer'>SimilarWeb</a> provided to us a seedlist of URLs across different sectors in the UK and US markets.</p>
        <p>Of these, we sorted by monthly traffic, and eliminated the top 500 in each sector, which commonly included sites like Google, YouTube, and Wikipedia, which would throw off results.</p>
        <p>We then used the next 500 URLs in each sector as the source list for this study.</p>
        <p>For each URL, we used <a href='https://ahrefs.com/' target='_blank' rel='noopener noreferrer'>Ahrefs</a> (who provided us with credits for this study) to find the first 120 domains referring to it. This number was selected as higher numbers have no upper limit in terms of time and expense to monitor (a single domain may have 2000 referring domains or more) and provide no significant benefit in terms of accuracy.</p>
        <p>The end figures, displayed in this study, refer to how many times each publication appeared among these referring domains.</p>
        <p>The UK/US split is determined by how many .co.uk and .com addresses listed each publication as a referring domain. That is, if example.co.uk in the Arts & Entertainment sector had Buzzfeed as a referring domain, Buzzfeed was counted once towards UK results in that sector.</p>
        <p>Of the list created, we then made major efforts to clean the top results of PR tools, URL-resharing sites, forums, and user-created content. The top 10 results in each industry were then manually curated by a small panel of experts in the industry to remove sites where links were primarily nofollow, to give a better indication of sites frequently offering follow links.</p>
        <p>Tech UK has been excluded due to low quality results with too few entries to build an effective analysis.</p>
      </PopupBlock>
      <UnderLay isVisible={intro || methodology} onClick={closeMainPopups}/>
    </SocialWrapper>
  )
}

export default Social
