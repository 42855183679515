import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Graph from '../components/graph'
import BubbleVis from '../components/bubblevis'
import data from '../assets/data.json'
import dataOverall from '../assets/dataOverall.json'
import descriptions from '../assets/descriptions.json'
import links from '../assets/links.json'
import * as vars from '../export'

const MainWrapper = styled.div`
  padding: 20px;
  width: calc(100% - 300px);
  flex-grow: 2;
  max-height: 100vh;
  overflow: auto;

  @media(max-width: 900px) {
    padding: 20px 20px 80px 20px;
    width: calc(100% - 250px);
  }
  @media(max-width: ${vars.sizes.mTablet}) {
    max-height: none;
    width: 100%;
  }

  .header {
    margin-bottom: 20px;

    .inner {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &.column {
        flex-direction: column;
      }

      @media (max-width: ${vars.sizes.mPhablet}) {
        flex-direction: column;
        align-items: center;
      }

      h1 {
        font-size: 36px;
        position: relative;
        padding-right: 20px;

        @media (max-width: ${vars.sizes.mPhablet}) {
          font-size: 30px;
          width: 100%;
          padding: 0;
          text-align: center;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 70%;
          height: 2px;
          background-color: ${vars.colors.green};

          @media (max-width: ${vars.sizes.mPhablet}) {
            left: 15%;
          }
        }
      }
      p {
        margin: 10px 0;

        @media (max-width: ${vars.sizes.mPhablet}) {
          text-align: center;
        }
      }
    }
    .para p {
      margin-top: 10px;
      font-size: 18px;
    }
  }
`
const Takeaways = styled.div`
  width: 100%;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      display: flex;
      min-width: 50px;
      margin-right: 10px;
    }
    p {
      margin: 0;
    }
    img {
      width: 50px;
    }
  }
`
const Toggle = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  p {
    &.active {
      font-weight: 600;
    }
  }

  span {
    &.greyed {
      filter: grayscale(1);
      position: relative;
    }

    &.oval {
      cursor: pointer;
      margin: 10px;
      position: relative;
      width: 50px;
      height: 25px;
      background-color: ${vars.colors.darkGreen};
      border-radius: 50px;
    }
    &.circle {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 50%;
      transform: translateY(-50%);
      left: ${props => (props.territory === 'UK' ? -2 : 50)}%;
      background-color: ${vars.colors.green};
      border-radius: 50px;
      transition: all .3s cubic-bezier(0.6, 0.13, 0.43, 0.96);
    }
  }

  &.no-data {
    .popup {
      pointer-events: none;
      opacity: 0;
      background-color: ${vars.colors.white};
      box-shadow: 0 0 5px -2px rgba(0,0,0,0.5);
      border-radius: 10px;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      padding: 10px;
      width: 150px;
      font-size: 15px;
      line-height: 1.5;
      color: ${vars.colors.black};
      transition: all .3s ease-out;
      z-index: 1;
    }

    &:hover {
      @media(hover: hover) {
        .popup {
          opacity: 1;
        }
      }
    }
  }
`
const DataVis = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${props => (props.isOverall ? 'calc(100% - 71px)' : '80%')};
  max-width: ${props => (props.isOverall ? 'none' : '1080px')};
  margin: auto;
  transition: all .3s ease-out;

  @media(max-width: ${vars.sizes.mDesktop}) {
    flex-wrap: wrap;
    height: auto;
  }
`
const StyledUl = styled.ul`
  position: relative;
  padding: 0 0 65px;
  list-style: none;
  background-color: ${vars.colors.grey};
  width: 100%;
  max-width: ${props => (props.isOverall ? props.tableVisible ? 300 : 0 : 300)}px;
  min-width: ${props => (props.isOverall ? props.tableVisible ? 250 : 0 : 250)}px;
  max-height: ${props => (props.isOverall ? '100%' : 'none')};
  right: ${props => (props.isOverall ? props.tableVisible ? 0 : -20 : 0)}px;
  margin: initial;
  transition: all .3s ease-out;
  align-self: ${props => (props.isOverall ? 'initial' : 'flex-start')};

  @media(max-width: ${vars.sizes.mDesktop}) {
    ${props => (props.isOverall ? props.tableVisible ? 'max-width: none;' : '' : 'max-width: none;')}
    max-height: ${props => (props.isOverall ? '410px' : 'none')};
  }

  li {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0 10px;

    p {
      display: inline-flex;
      align-items: center;
      margin: 2px 0; padding: 0;

      span {
        text-align: center;
        min-width: 30px;
        min-height: 30px;
        margin-right: 10px;
        padding: 5px 0;
        background-color: ${vars.colors.green};
      }

      &.figure {
        font-weight: 600;
      }
    }

    &.backlink {
      transition: all .3s ease-out;

      p {
        color: ${vars.colors.black};
      }
    }

    &.titlebar {
      position: relative;
      margin-bottom: 10px;
      background-color: ${vars.colors.teal};
      padding: 10px;
      color: ${vars.colors.white};
    }
  }

  .currentHover {
    background-color: ${vars.colors.green} !important;

    p {
      color: ${vars.colors.white} !important;
    }
  }

  .maxheight {
    position: relative;
    height: calc(100% - 43px);
    overflow: ${props => (props.isOverall ? 'auto' : 'initial')};
    padding: 0;

    ul {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;

      @media(max-width: ${vars.sizes.mDesktop}) {
        position: static;
      }
    }
  }
`
const DownloadDropdown = styled.li`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: ${props => (props.expanded ? '120px' : '40px')};
  padding: 0 !important;
  margin: 10px auto 5px !important;
  background-color: ${vars.colors.green};
  cursor: pointer;

  a {
    position: absolute;
    top: 40px;
    color: ${vars.colors.white} !important;
    font-weight: 600 !important;
    padding: 10px 40px 10px 20px !important;
    text-decoration: none;
    width: 100%;
    height: 40px;
    line-height: 1.2;
    pointer-events: ${props => (props.expanded ? 'auto' : 'none')};
    opacity: ${props => (props.expanded ? 1 : 0)};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background-image: url(${vars.icons.download});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      pointer-events: none;
    }
    &:hover {
      @media(hover: hover) {
        background-color: ${vars.colors.darkGreen};
      }
    }

    &:last-of-type {
      top: 80px;
    }
  }
  p {
    display: block !important;
    color: ${vars.colors.white} !important;
    font-weight: 600 !important;
    padding: 10px 40px 10px 20px !important;
    margin: 0 !important;
    width: 100%;
    height: 40px;
    will-change: background-color;
    transition: background-color .3s ease-out;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background-image: url(${vars.icons.arrow});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      pointer-events: none;
      will-change: transform;
      transition: transform .3s ease-out;
      transform: ${props => (props.expanded ? 'translateY(-50%) rotate(-90deg)' : 'translateY(-50%) rotate(90deg)')};
    }
    &:hover {
      @media(hover: hover) {
        background-color: ${vars.colors.darkGreen};
      }
    }
  }
`
const DownloadButton = styled.li`
  width: 150px;
  height: 30px;
  padding: 0 !important;
  margin: 10px auto 5px !important;
  background-color: ${vars.colors.green};
  position: relative;
  will-change: background-color;
  transition: background-color .3s ease-out;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-image: url(${vars.icons.download});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: none;
  }
  &:hover {
    @media(hover: hover) {
      background-color: ${vars.colors.darkGreen};
    }
  }
  a {
    display: block;
    width: 100%;
    padding: 0 40px 0 20px !important;
    line-height: 30px;
    text-decoration: none;
    font-weight: 600;
    color: ${vars.colors.white};
  }
`
const TableToggle = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 43px;
  height: 100%;
  top: 0;
  right: 100%;
  background-color: ${vars.colors.green};
  padding: 12px 15px;
  cursor: pointer;

  @media(max-width: ${vars.sizes.mDesktop}) {
    display: none;
  }

  img {
    width: 100%; height: 100%;
    transform: rotate(${props => (props.tableVisible ? '0deg' : '180deg')});
    will-change: transform;
    transition: transform .3s ease-out;
  }
  &:hover img {
    @media(hover: hover) {
      transform: rotate(${props => (props.tableVisible ? '0deg' : '180deg')}) scale(1.2);
    }
  }
`

const Main = ({ location, territory, setTerritory }) => {
  const [activeHover, setActiveHover] = useState(undefined)
  const currentPage = location.pathname.split('/')[1]
  const [tableVisible, setTableVisible] = useState(true)
  const industryKeys = Object.keys(data[0])
  const currentIndustry = industryKeys.map(category => (category.toLowerCase().replace(/ /g, '').replace(/&/g, '') === currentPage && category)).filter(x => x !== false)[0]
  const dataLocal = data.slice().filter(x => x.Territory === territory && x[currentIndustry])
  const dataLocalOverall = dataOverall.slice()
  const isOverallPage = currentPage === 'overall' || currentPage === ''
  const description = currentIndustry ? (!isOverallPage && descriptions.filter(x => x[currentIndustry])[0][currentIndustry][territory]) : ''
  const [headerHeight, setHeaderHeight] = useState(0)
  const [downloadExpanded, setDownloadExpanded] = useState(false)

  useEffect(() => {
    if (!isOverallPage && dataLocal.length < 1) setTerritory('US')
    setTimeout(() => {
      setHeaderHeight(document.getElementById('header').getBoundingClientRect().height)
    }, 100)
  }, [isOverallPage, currentPage, dataLocal.length, setTerritory])

  const handleTerritory = () => {
    localStorage.setItem('territory', territory === 'UK' ? 'US' : 'UK')
    setTerritory(territory === 'UK' ? 'US' : 'UK')
  }

  return (
    <MainWrapper isOverall={isOverallPage}>
      {currentIndustry ? (
        <div id='header' className='header'>
          <div className='inner'>
            <h1>The best publications for backlinks in {currentIndustry}</h1>
            <Toggle territory={territory} className={`${currentIndustry === 'Tech' ? 'no-data' : ''}`}>
              <p className={territory === 'UK' ? 'active' : ''}>UK</p>
              <span className={`${currentIndustry === 'Tech' ? 'greyed' : ''} oval`} onClick={() => handleTerritory()}>
                <span className='circle'/>
              </span>
              <p className={territory === 'US' ? 'active' : ''}>USA</p>
              {currentIndustry === 'Tech' && (
                <span className='popup'>
                  {descriptions.filter(x => x[currentIndustry])[0][currentIndustry].UK}
                </span>
              )}
            </Toggle>
          </div>
          { !isOverallPage && <div className='para' dangerouslySetInnerHTML={{ __html: description }}/> }
        </div>
      ) : currentPage !== 'conclusions' ? (
        <div id='header' className='header'>
          <div className='inner column'>
            <h1>Most recurring backlinks</h1>
            <p>The chart shows which publications provide outbound links to the most domains, with a larger size representing a higher number.</p>
          </div>
        </div>
      ) : null}
      <DataVis isOverall={dataLocal.length < 1} headerHeight={headerHeight}>
        {currentIndustry ? (
          <Graph data={dataLocal} currentIndustry={currentIndustry} activeHover={activeHover} setActiveHover={setActiveHover}/>
        ) : currentPage === 'conclusions' ? (
          <div id='header' className='header'>
            <Takeaways>
              <h1>Key Takeaways</h1>
              <ul>
                <li>
                  <span><img src={vars.icons.us} alt='us'/></span>
                  <p>US outreach is more likely to link where focusing on major industry-specific sites on the whole, but there are some recurring publications which are suitable for all outreach (Forbes, New York Times, BuzzFeed).</p>
                </li>
                <li>
                  <span><img src={vars.icons.uk} alt='uk'/></span>
                  <p>UK outreach is more generic and best served by going to local area sites like Get Surrey and the Hinckley Times or similar.</p>
                </li>
                <li>
                  <span><img src={vars.icons.techBeauty} alt='tech-beauty'/></span>
                  <p>Tech and Beauty & Fitness sectors offer fewer potential links overall, while Shopping and Travel offer more.</p>
                </li>
                <li>
                  <span><img src={vars.icons.beautyFitness} alt='beauty-fitness'/></span>
                  <p>Beauty and Fitness, however, are also less affected by location, with significant overlap across the US and UK top referring domain lists.</p>
                </li>
                <li>
                  <span><img src={vars.icons.usUk} alt='us-uk'/></span>
                  <p>There generally aren’t significant differences in link opportunities between the US and the UK, with only a moderately higher number among US domains, and not in all sectors.</p>
                </li>
                <li>
                  <span><img src={vars.icons.all} alt='all'/></span>
                  <p>Beauty and Fitness, Shopping, and Travel content pages in the UK all show an overall greater number of backlinks than those in the US.</p>
                </li>
              </ul>
              <p>You can find a downloadable PDF of this study <a href='https://klipr.io/resources/backlinks-study/whitepaper.pdf'>here</a>.</p>
            </Takeaways>
          </div>
        ) : (
          <BubbleVis data={dataOverall} activeHover={activeHover} setActiveHover={setActiveHover}/>
        )}
        {currentPage !== 'conclusions' && (
          <StyledUl isOverall={dataLocal.length < 1} tableVisible={tableVisible}>
            <li className='titlebar'>
              {dataLocal.length < 1 && (
                <TableToggle onClick={() => setTableVisible(!tableVisible)} tableVisible={tableVisible}>
                  <img src={vars.icons.arrow} alt='arrow'/>
                </TableToggle>
              )}
              <p>Domain</p>
              <p>Backlinks</p>
            </li>
            {dataLocal.length > 1 ? (
              <>
                {dataLocal.map((industry, i) => (
                  <li key={industry[currentIndustry]} className={`${activeHover === industry[currentIndustry] ? 'currentHover' : ''} backlink`}>
                    <p><span>{i + 1}</span>{industry[currentIndustry]}</p>
                    <p className='figure'>{industry[`${currentIndustry} Links`]}</p>
                  </li>
                ))}
                <DownloadDropdown expanded={downloadExpanded}>
                  <p onClick={() => setDownloadExpanded(!downloadExpanded)}>Download...</p>
                  <a href={links.Top10} target='_blank' rel='noreferrer noopener'>Top 10</a>
                  <a href={links[currentIndustry]} target='_blank' rel='noreferrer noopener'>Raw Data</a>
                </DownloadDropdown>
              </>
            ) : (
              <>
                <li className='maxheight'>
                  <ul>
                    {dataLocalOverall.map((link, i) => (
                      <li key={link.Site} className={`${activeHover === link.Site ? 'currentHover' : ''} backlink`}>
                        <p><span>{i + 1}</span>{link.Site}</p>
                        <p className='figure'>{link.Links}</p>
                      </li>
                    ))}
                  </ul>
                </li>
                <DownloadButton>
                  <a href={links.Overall} target='_blank' rel='noreferrer noopener'>Download...</a>
                </DownloadButton>
              </>
            )}
          </StyledUl>
        )}
      </DataVis>
    </MainWrapper>
  )
}

export default Main
