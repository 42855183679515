import React from 'react'
import styled from 'styled-components'
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import * as vars from '../export'

const GraphWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 300px;

  @media(max-width: ${vars.sizes.mDesktop}) {
    height: auto;
  }
`
const StyledToolTip = styled.div`
  background-color: ${vars.colors.white};
  padding: 10px;
  border-radius: 0 10px 10px;
  box-shadow: 0px 3px 6px #00000029;

  p {
    color: ${vars.colors.black};
    width: 100%;
    text-align: center;
    font-weight: 300;

    &:nth-child(2) {
      color: ${vars.colors.green};
      font-weight: 600;
    }
  }
`

const Graph = ({ data, currentIndustry, setActiveHover }) => {
  const graphData = data.map(industry => ({
    name: industry[currentIndustry],
    links: industry[`${currentIndustry} Links`]
  }))

  return (
    <GraphWrapper>
      <ResponsiveContainer>
        <BarChart height={500} data={graphData} margin={{ top: 15, right: 30, left: 20, bottom: 5 }} onMouseMove={value => setActiveHover(value.activeLabel)}>
          <CartesianGrid stroke='#ccc' strokeDasharray='3 3'/>
          <XAxis dataKey='name' interval={0} angle={-50} height={160} textAnchor='end'/>
          <YAxis domain={[0, 380]}/>
          <Tooltip payload={[{ name: 'name', value: 'links' }]} content={value => (
            value.payload[0] && (
              <StyledToolTip>
                <p>{value.payload[0].payload.name}</p>
                <p>{value.payload[0].payload.links}</p>
              </StyledToolTip>
            ))}
          />
          <Bar dataKey='links' fill={vars.colors.green}/>
        </BarChart>
      </ResponsiveContainer>
    </GraphWrapper>
  )
}
export default Graph
