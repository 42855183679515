import logo from './assets/icons/logo.svg'
import facebook from './assets/icons/facebook.svg'
import twitter from './assets/icons/twitter.svg'
import linkedin from './assets/icons/linkedin.svg'
import arrow from './assets/icons/arrow.svg'
import download from './assets/icons/download.svg'
import all from './assets/icons/all.svg'
import us from './assets/icons/us.svg'
import uk from './assets/icons/uk.svg'
import usUk from './assets/icons/us-uk.svg'
import beautyFitness from './assets/icons/beauty-fitness.svg'
import techBeauty from './assets/icons/tech-beauty.svg'

const icons = {
  logo,
  twitter,
  linkedin,
  facebook,
  arrow,
  download,
  all,
  us,
  uk,
  usUk,
  beautyFitness,
  techBeauty
}
const colors = {
  black: '#000000',
  white: '#FFFFFF',
  grey: '#E6E6E6',
  teal: '#465e69',
  green: '#00BF8E',
  lightGreen: '#00F1C4',
  darkGreen: '#00664C',
  blue: '#1E66C9'
}
const sizes = {
  mWide: '1440px',
  mLarge: '1200px',
  mDesktop: '1024px',
  mMedium: '920px',
  mTablet: '768px',
  mBetween: '720px',
  mPhablet: '650px',
  mPhone: '500px',
  mSmall: '400px',
  mSmaller: '320px'
}

export { colors, sizes, icons }
